.terms {
    font-size: 1rem;
}

@media (max-width: 1199.98px) {
    .terms {
        font-size: 0.7rem;
    }
}

@media (min-width: 1200px) {
    .login-error-message {
        position: absolute;
    }
}
