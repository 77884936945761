.sidebar-toggle {
    color: white;
    font-weight: var(--bs-nav-link-font-weight);
}
.sidebar-toggle:hover {
    color: var(--bs-nav-link-hover-color);
}
.sidebar {
    background-color: var(--ra-dark-blue);
    color: white;
    font-size: 15px;
    height: 100vh;
    width: 280px;
    display: flex;
    flex-direction: column;
}
.sidebar .navbar-brand {
    height: 60px;
}
.sidebar hr {
    margin: 0.25rem 0;
    border-top: 1px solid var(--ra-dark-blue);
}

.sidebar .nav-pills {
    font-weight: bold;
    /* --bs-nav-pills-border-radius: 0.375rem !important; */
    --bs-nav-pills-border-radius: 0 !important;
    --bs-nav-pills-link-active-color: var(--ra-dark-blue) !important;
    --bs-nav-link-color: white !important;
    --bs-nav-pills-link-active-bg: var(--ra-yellow) !important;
    --bs-nav-link-hover-color: lightgray !important;
}
#sidebar-collapse.collapse:not(.show) {
    display: flex;
    width: var(--ra-sidebar-width);
    overflow: hidden;
}

#sidebar-collapse.collapsing:not(.show) {
    width: var(--ra-sidebar-width);
    min-height: 100vh;
}

.navbar {
    height: var(--ra-navbar-height);
    background-color: var(--ra-dark-blue);
    width: 100vw;
}
.navbar .nav-link {
    font-weight: bold;
}

.navbar-collapse {
    background-color: var(--ra-dark-blue);
}

.navbar-brand svg {
    transition: 0.35s ease;
}
.nav-tooltip .tooltip-inner {
    color: var(--ra-dark-blue);
    background-color: var(--ra-yellow);
    color: white;
    background-color: var(--ra-dark-blue);
    padding: 10px;
    margin-left: 15px;
    font-weight: bold;
}
.nav-tooltip .tooltip-arrow {
    margin-left: 15px;
}
.nav-tooltip .tooltip-arrow::before {
    border-right-color: var(--ra-dark-blue);
}
