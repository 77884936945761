.music-test-report-table {
    min-width: 1000px;
}

.validation-typehead {
    z-index: 2;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid var(--ra-yellow);
    background-color: white;
    color: black;
    padding: 8px;
    display: flex;
}
/* styles.css */
.w-xl-75 {
    width: 100%;
}

@media (min-width: 1200px) {
    .w-xl-75 {
        width: 75%;
    }
}
