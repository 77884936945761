.spins-input {
    width: 75px;
}
table.music-test {
    min-width: 1000px;
}

.music-test-short-cols .col {
    width: 14.2857%;
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
}

.music-test-short-cols.with-deltas .col {
    width: 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.music-test-mw {
    min-width: 1000px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-style: hidden;
}

/* Table */
table {
    --bs-table-hover-bg: var(--ra-light-yellow) !important;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: separate !important;
    overflow: hidden;
    border: 1px solid var(--ra-dark-blue) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
table thead tr {
    background-color: var(--ra-dark-blue);
}
table thead th {
    color: white !important;
}
table td,
table th {
    background-color: transparent !important;
    vertical-align: middle;
}
table th svg,
.table-header svg {
    color: var(--ra-yellow);
    height: 15px;
    width: 15px;
}
table tr.invalid td {
    color: var(--bs-tertiary-color) !important;
}

.table.selectable tr.active {
    background-color: var(--ra-yellow);
}

.table-container-hover .border-bottom.active {
    background-color: var(--ra-light-yellow);
}
