:root {
    --ra-dark-blue: #142533;
    --ra-dark-light: #485561;
    --ra-yellow: #febe10;
    --ra-light-yellow: rgba(254, 190, 16, 0.25);
    --ra-light: #f5f5f5;
    --ra-sidebar-width: 59px;
    --ra-navbar-height: 48px;
    --toastify-text-color-light: var(--ra-dark-blue);
    --toastify-color-success: var(--bs-success);
    --toastify-color-warning: var(--bs-warning);
    --toastify-color-error: var(--bs-danger);
    --toastify-color-info: var(--bs-info);
    --toastify-color-progress-warning: var(--ra-dark-blue);
    --toastify-text-color-warning: var(--ra-dark-blue);

    --rs-bg-active: var(--ra-yellow) !important;
    --rs-calendar-cell-selected-hover-bg: #e2aa0f !important;
    --rs-calendar-date-selected-text: var(--ra-dark-blue) !important;
    --rs-calendar-range-bg: var(--ra-light-yellow) !important;
    --rs-listbox-option-hover-bg: var(--ra-light-yellow) !important;
    --rs-listbox-option-hover-text: var(--ra-dark-blue) !important;
    --rs-input-focus-border: var(--ra-yellow) !important;
    --rs-picker-value: var(--ra-dark-blue) !important;
    --rs-text-primary: var(--ra-dark-blue) !important;
    --rs-btn-subtle-hover-bg: var(--ra-light-yellow) !important;
    --rs-btn-subtle-active-bg: var(--ra-yellow) !important;
    --rs-btn-subtle-hover-text: var(--ra-dark-blue) !important;
    --rs-btn-subtle-active-text: var(--ra-dark-blue) !important;
    --rs-text-active: var(--ra-dark-blue) !important;
    --rs-input-disabled-bg: var(--bs-secondary-bg) !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
    /* font-family: Roboto; */
    font-size: 15px;
    color: #061f31;
    letter-spacing: 0.05em;
    background-color: #f5f5f5 !important;
    min-height: 100vh;
    width: 100%;
}

#root {
    display: flex;
    overflow: auto;
    min-height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

main {
    margin-left: var(--ra-sidebar-width);
    width: calc(100% - var(--ra-sidebar-width));
}

@media (max-width: 1199.98px) {
    main {
        margin-left: 0;
        margin-top: var(--ra-navbar-height);
        width: 100%;
    }
}

h1 {
    margin-bottom: 1.5rem !important;
}

h1.h1-lg {
    font-size: 3rem;
}

/* backgrounds */
.bg-radio_blue,
.bg-ra-dark-blue {
    background-color: var(--ra-dark-blue) !important;
}
.bg-radio_blue_light {
    background-color: var(--ra-dark-light);
}
.bg-yellow {
    background-color: #febe10;
}

.bg-beige {
    background-color: #f1ebeb;
}

/* font-color */
.font-radio_blue {
    color: var(--ra-dark-blue);
}
.c-red {
    color: rgb(117, 15, 15);
}

/* marigns */
.ms-10em {
    margin-left: 10em;
}

/* width */
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-90 {
    width: 90%;
}

/* RA Buttons */
.btn-ra-yellow {
    --bs-btn-color: var(--ra-dark-blue);
    --bs-btn-bg: var(--ra-yellow);
    --bs-btn-border-color: var(--ra-yellow);
    --bs-btn-hover-color: var(--ra-dark-blue);
    --bs-btn-hover-bg: #e2aa0f;
    --bs-btn-hover-border-color: #e2aa0f;
    --bs-btn-active-color: var(--ra-dark-blue);
    --bs-btn-active-bg: #cc9e07;
    --bs-btn-active-border-color: #cc9e07;
    --bs-btn-disabled-color: var(--ra-dark-blue);
    --bs-btn-disabled-bg: var(--ra-yellow);
    --bs-btn-disabled-border-color: var(--ra-yellow);
}

.btn-ra-dark-blue {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: var(--ra-dark-blue);
    --bs-btn-border-color: var(--ra-dark-blue);
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #0f1a27;
    --bs-btn-hover-border-color: #0f1a27;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #0f1a27;
    --bs-btn-active-border-color: #0f1a27;
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: var(--ra-dark-blue);
    --bs-btn-disabled-border-color: var(--ra-dark-blue);
}

.btn-ra-light {
    --bs-btn-color: var(--ra-dark-blue);
    --bs-btn-bg: var(--ra-light);
    --bs-btn-border-color: var(--ra-light);
    --bs-btn-hover-color: var(--ra-dark-blue);
    --bs-btn-hover-bg: #e0e0e0;
    --bs-btn-hover-border-color: #e0e0e0;
    --bs-btn-active-color: var(--ra-dark-blue);
    --bs-btn-active-bg: #d5d5d5;
    --bs-btn-active-border-color: #d5d5d5;
    --bs-btn-disabled-color: var(--ra-dark-blue);
    --bs-btn-disabled-bg: var(--ra-yellow);
    --bs-btn-disabled-border-color: var(--ra-yellow);
}

.rs-btn-primary {
    --rs-btn-primary-text: var(--ra-dark-blue);
    --rs-btn-primary-bg: var(--ra-yellow);
    --rs-btn-primary-border-color: var(--ra-yellow);
    --rs-btn-primary-hover-color: var(--ra-dark-blue);
    --rs-btn-primary-hover-bg: #e2aa0f;
    --rs-btn-primary-hover-border-color: #e2aa0f;
    --rs-btn-primary-active-color: var(--ra-dark-blue);
    --rs-btn-primary-active-bg: #cc9e07;
    --rs-btn-primary-active-border-color: #cc9e07;
    --rs-btn-primary-disabled-color: var(--ra-dark-blue);
    --rs-btn-primary-disabled-bg: var(--ra-yellow);
    --rs-btn-primary-disabled-border-color: var(--ra-yellow);
}

.btn-ra-light:focus,
.btn-ra-dark-bluet:focus,
.btn-ra-yellow:focus {
    color: var(--bs-btn-active-color);
}

.btn-download:hover {
    color: var(--ra-yellow);
}

/* Inputs */

.btn-ra-yellow:focus,
.form-control:focus,
input:focus:not(.rbt-input-main):not(.rs-picker-toggle-textbox):not(.input-group input),
.rbt-input.focus,
select:focus {
    box-shadow: 0 0 0 0.25rem var(--ra-light-yellow) !important;
    border-color: var(--ra-yellow) !important;
}

select option:focus {
    background-color: var(--ra-yellow) !important;
    color: var(--ra-dark-blue);
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;
    background-color: lightgray;
    border-color: lightgray;
}
.form-switch .form-check-input:checked {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(254, 190, 16)'/%3e%3c/svg%3e") !important;
    background-color: var(--ra-light-yellow);
    border-color: var(--ra-light-yellow);
}

.btn-group .btn-check:checked + .btn {
    background-color: var(--ra-dark-blue);
    border-color: var(--ra-dark-blue);
    color: var(--ra-light);
}
.ra-switcher label {
    padding: 7px 25px;
    font-weight: bold;
    border-color: var(--ra-dark-blue);
    color: var(--ra-dark-blue);
    text-transform: uppercase;
    font-size: 12px;
    min-width: 130px;
    width: 50%;
}
.ra-switcher label:hover {
    border-color: var(--ra-dark-blue) !important;
}

@media (max-width: 1400px) {
    .ra-switcher label {
        min-width: 100px;
        padding: 7px 15px;
    }
}

.input-group {
    border-radius: var(--bs-border-radius);
}
.input-group:focus-within {
    box-shadow: 0 0 0 0.25rem var(--ra-light-yellow) !important;
    border-color: var(--ra-yellow) !important;
}
.input-group:focus-within .input-icon {
    border-color: var(--ra-yellow) !important;
}
.input-group input:focus:not(:valid) {
    box-shadow: none !important;
    border-color: var(--ra-yellow) !important;
}
.input-group .input-icon {
    border-right: 0;
    background-color: white;
    color: var(--gray-600);
}
.input-group .input-icon + input {
    border-left: 0;
}

.rs-picker {
    --rs-input-focus-border: var(--ra-yellow) !important;
    --rs-state-focus-shadow: 0 0 0 0.25rem var(--ra-light-yellow) !important;
}

.rs-picker-toggle-placeholder,
.rs-picker-toggle-value {
    letter-spacing: normal;
}

.rbt-token {
    color: var(--ra-dark-blue);
    background-color: var(--ra-light-yellow);
}

.rbt-token-active,
.rbt-menu .dropdown-item.active {
    color: var(--ra-dark-blue);
    background-color: var(--ra-yellow);
}

.rbt.autocomplete a {
    color: rgb(74, 74, 74);
}

.rs-stack-item {
    width: 100%;
    --rs-btn-link-text: var(--ra-dark-blue);
    --rs-btn-link-hover-text: var(--ra-dark-blue);
}

.rs-picker .rs-stack-item:last-child {
    width: 0;
}

.rs-btn-link {
    width: 100% !important;
    border: 2px transparent solid !important;
}

.rs-btn-link:focus,
.rs-btn-link:hover {
    background-color: var(--ra-light-yellow) !important;
    border-color: var(--ra-yellow) !important;
    text-decoration: none !important;
}
.rs-picker-disabled {
    opacity: 1 !important;
}

/* Table */
table {
    --bs-table-hover-bg: var(--ra-light-yellow) !important;
    table-layout: fixed;
    border-collapse: separate !important;
    overflow: hidden;
    border: 1px solid var(--ra-dark-blue) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
table thead tr {
    background-color: var(--ra-dark-blue);
}
table thead th {
    color: white !important;
}
table td,
table th {
    background-color: transparent !important;
    vertical-align: middle;
}
table th svg {
    color: var(--ra-yellow);
    height: 15px;
    width: 15px;
}
table tr.invalid td {
    color: var(--bs-tertiary-color) !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-style: hidden;
}

.plap-table {
    min-width: 1350px;
}

.fs-4vh {
    font-size: 4vh;
}
.color-ra-yellow {
    color: var(--ra-yellow);
}
.color-ra-dark-blue {
    color: var(--ra-dark-blue);
}
.icon-btn {
    min-height: 31px;
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
}
.icon-btn svg,
.sort-btn svg {
    height: 18px;
    width: 18px;
    vertical-align: baseline;
}
.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item:hover {
    color: var(--ra-dark-blue);
    background-color: var(--ra-yellow);
    text-decoration: none;
}
.dropdown-item {
    padding: 2px 8px;
    font-size: 0.875rem;
}
.score-worst {
    color: #e8304f !important;
}
.score-worse {
    color: #fa8128 !important;
}
.score-middle {
    color: #80cbc4 !important;
}
.score-better {
    color: #9ccc65 !important;
}
.score-best {
    color: #388e3c !important;
}
.gray_color_for_same_category {
    color: #dcdcdc !important;
}

.bg-score-worst {
    background-color: #e8304f !important;
}
.bg-score-worse {
    background-color: #fa8128 !important;
}
.bg-score-middle {
    background-color: #80cbc4 !important;
}
.bg-score-better {
    background-color: #9ccc65 !important;
}
.bg-score-best {
    background-color: #388e3c !important;
}

.bold {
    font-weight: bold !important;
}

.min-w-perspective {
    min-width: 150px;
}

.score {
    font-weight: bold;
    color: white !important;
    height: 2rem;
    width: 2rem;
    border-radius: var(--bs-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
}

.score.invalid {
    background-color: gray !important;
}

.score-worst.invalid,
.score-best.invalid {
    color: gray !important;
}

.color-square {
    height: 12px;
    width: 12px;
    display: inline-block;
}

.tooltip-info {
    color: #febe10;
    position: relative;
    display: inline-flex;
    vertical-align: text-bottom;
}

.tooltip-info svg {
    height: 16px;
    width: 16px;
}

.tooltip-info .tooltiptext {
    visibility: hidden;
    display: none;
    font-size: 12px;
    max-width: 450px;
    min-width: 150px;
    min-height: 50px;
    background-color: #fff;
    border-left: 8px solid var(--ra-dark-blue);
    border-right: 1px solid var(--ra-dark-blue);
    border-top: 1px solid var(--ra-dark-blue);
    border-bottom: 1px solid var(--ra-dark-blue);
    box-shadow: 3px 6px 6px var(--ra-dark-blue);
    vertical-align: middle;
    color: #444444;
    text-align: center;
    padding: 5px 5px 5px 5px;
    text-transform: none;
    font-weight: normal;
    width: 220px;
    text-align: left;
    position: absolute;
    z-index: 100;
}

.tooltip-info:hover .tooltiptext {
    display: inline-block;
    visibility: visible;
}

.card {
    width: 300px;
    height: auto;
    overflow: hidden;
}

.card img {
    width: 100%;
    transition: 0.5s all ease-in-out;
}

.card:hover img {
    transform: scale(1.1);
}

.error-container h3 {
    font-size: 1.5rem;
}

@media (max-width: 1199.98px) {
    .error-container h3 {
        font-size: 1rem;
    }
    .error-container p {
        font-size: 0.875rem;
    }
}

@media (max-width: 992px) {
    .trend-over-time-modal .modal-dialog {
        max-width: none;
        margin-left: var(--bs-modal-margin);
        margin-right: var(--bs-modal-margin);
    }
}

.iframe-styling {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.iframe-styling iframe {
    position: absolute;
    top: 0;
    left: 0;
}

.btn-back-to-top {
    position: fixed;
    padding: 0.25rem;
    z-index: 1000;
    bottom: 30px;
    right: 30px;
    opacity: 0.75;
    --bs-btn-color: var(--ra-yellow);
    --bs-btn-bg: var(--ra-dark-blue);
    --bs-btn-border-color: var(--ra-dark-blue);
    --bs-btn-hover-color: var(--ra-yellow);
    --bs-btn-hover-bg: #0f1a27;
    --bs-btn-hover-border-color: #0f1a27;
    --bs-btn-active-color: var(--ra-yellow);
    --bs-btn-active-bg: #0f1a27;
    --bs-btn-active-border-color: #0f1a27;
}
.btn-back-to-top:hover {
    opacity: 1;
}

.mh-500 {
    max-height: 500px;
}
